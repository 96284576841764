<template>

  <div>

    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="groupTypeOptions"
            :clearable="true"
            :placeholder="'Select Type'"
            value="value"
            label="text"
            multiple
            :reduce="type => type.value"
          />
        </b-col>

        <b-col
          cols="12"
          md="2"
          class="w-100"
        >
          <v-select
            v-model="filterDataStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOptions"
            :clearable="false"
            :placeholder="'Select Status'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-if="filterSessionOptions.length > 0"
            v-model="filterDataSession"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterSessionOptions"
            :reduce="session => session.id"
            :clearable="false"
            :placeholder="`Select ${sessionSemester(1)}`"
          />
          <v-select
            v-else
            :placeholder="`Select ${sessionSemester(1)}`"
          />
        </b-col>

        <b-col
          cols="12"
          md="2"
          class="w-100"
        >
          <v-select
            v-model="filterDataTuition"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterTuitionFeeOptions"
            :clearable="false"
            :reduce="item => item.value"
            :placeholder="'Select Fee Type'"
          />
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="0.7"
      rounded="sm"
    >
      <b-card
        no-body
      >

        <div class="table-header m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-checkbox
                v-model="groupsPerPageCheckbox"
                class="mr-1 bulk-checkbox"
                @change="markGroupsOnPage"
              />

              <label class="mr-1 mb-0">Show</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :value="perPage"
                :clearable="false"
                :reduce="option => option.value"
                class="per-page-selector d-inline-block ml-50 mr-1"
                @input="updatePerPage"
              />
              <b-button
                variant="outline-primary"
                @click="toggleExpandAll"
              >
                <span class="mr-25 align-middle">{{ expandAll ? 'Collapse all' : 'Expand All' }}</span>
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-b-modal.edit-bulk-groups
                  class="mx-auto"
                  variant="primary"
                  :disabled="!$can('update', permissionSubjects.Group) || markedGroups.length < 2"
                >
                  <span class="mr-25 align-middle">Bulk Change</span>
                </b-button>

                <label class="mr-1 mb-0">Search</label>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search"
                  class="w-25"
                />

                <!-- Dropdown -->
                <b-dropdown
                  variant="primary"
                  toggle-class="p-0"
                  no-caret
                  right
                  class="h-100 ml-1"
                >

                  <template
                    #button-content
                  >
                    <feather-icon
                      icon="GridIcon"
                      size="19"
                      class="m-50"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="$can('create', permissionSubjects.Group)"
                    v-b-modal.add-group
                  >
                    <span>Create Group</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('create', permissionSubjects.GroupType)"
                    v-b-modal.add-group-type
                  >
                    <span>Create Group Type</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('add', permissionSubjects.GroupMember)"
                    v-b-modal.bulk-assign
                  >
                    <span>Bulk Assign</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="exportGroups"
                  >
                    <span>Export Groups List</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('create', permissionSubjects.Group)"
                    v-b-modal.bulk-create
                  >
                    <span>Bulk Create Groups</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('manage', permissionSubjects.GroupWizard)"
                    :to="{ name: 'admin-group-wizard-list' }"
                  >
                    <span>Wizards</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.new-task-modal
                  >
                    <span>Assign task</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refInvoiceListTable"
          :items="fetchGroupList"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          sticky-header="75vh"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative table-sticky-head"
        >
          <!-- Loader -->
          <template #table-busy>
            <TableSpinner />
          </template>

          <!-- Column: show_details -->
          <template #cell(show_details)="data">
            <feather-icon
              :icon="data.item._showDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="16"
              class="align-middle text-body"
              @click="data.toggleDetails"
            />
          </template>

          <template #row-details="row">
            <group-users
              :group-id="row.item.id"
              :program-id="programId"
              :is-all-expanded="expandAll"
              :group-users="getGroupUsersByGroupId(row.item.id)"
            />
          </template>

          <!-- Column: group -->
          <template #cell(name)="data">
            <b-form-checkbox
              v-model="markedGroups"
              :value="data.item.id"
              inline
              @change="handleGroupsPerPageCheckbox"
            />
            <b-avatar
              class="mr-1"
              size="32"
              :src="data.item.logo"
            />
            <b-link
              :to="{ name: 'groups-detail', params: { id: data.item.id } }"
            >{{ data.item.name }}</b-link>
          </template>

          <!-- Column: type -->
          <template #cell(type)="data">
            <b-badge variant="light-secondary">
              {{ data.value }}
            </b-badge>
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              :variant="`light-${resolveStatusVariant(data.value)}`"
            >
              {{ data.value }}
            </b-badge>
          </template>

          <!-- Column: has_tuition_fee -->
          <template #cell(has_tuition_fee)="data">
            <b-badge variant="light-secondary">
              {{ data.item.has_tuition_fee ? 'Tuition' : 'Fee' }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="mx-auto p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <template
                v-for="option in actionOptions"
              >
                <b-dropdown-item
                  v-if="option.link"
                  :key="option.value"
                  :to="{ name: option.link, params: { id: data.item.id } }"
                >
                  <span>{{ option.title }}</span>
                </b-dropdown-item>
              </template>

              <b-dropdown-item
                v-if="$can('delete', permissionSubjects.Group)"
                @click="deleteItem(data.item)"
              >
                <span>Delete Group</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="openGenerateGroupListTemplateModal(data.item)"
              >
                <span>Group List Template</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="openGenerateBagesModal(data.item)"
              >
                <span>Generate Badges</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="downloadBages(data.item)"
              >
                <span>Download Badges</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-if="totalItems && perPage !== 'all'"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>

      </b-card>
    </b-overlay>

    <group-create-modal
      :group-type-options="groupTypeOptions"
      @reload="refetchData"
      @new-group-created="findApplicationsForAutoAssign"
    />

    <group-type-create-modal
      @reload="updateGroupsData"
    />

    <group-bulk-edit-modal
      :marked-groups="markedGroups"
      :group-type-options="groupTypeOptions"
      @reload="finishBulkEdit"
    />

    <group-bulk-assign-modal
      @reload="refetchData"
    />

    <group-bulk-create-modal
      @reload="refetchData"
    />

    <warning-modal
      :toggle-modal="warningModalToggle"
      :type="'-delete-group'"
      :title="'This Group can\'t be deleted because there are some users assigned to this group'"
      :message="'Please unassigned Users from this group first'"
      :show-checkbox="true"
      :checkbox-text="'Delete anyway'"
      @close-modal="warningModalToggle = !warningModalToggle"
      @confirm="deleteGroupIfExist"
    />

    <confirmation-modal
      :toggle-modal="confirmationModalToggle"
      :type="'-delete-group'"
      :title="'Are you sure'"
      :message="'Are you sure you want to delete this group?'"
      @close-modal="confirmationModalToggle = !confirmationModalToggle"
      @confirm="deleteGroupIfExist"
    />

    <applications-for-group-auto-assign-modal
      v-if="applicationsForAutoAssign.length"
      :group-id="createdGroupId"
      :applications-list="applicationsForAutoAssign"
      @updated="refetchData"
    />

    <generate-group-list-template-modal
      v-if="selectedGroup"
      :group-id="selectedGroup.id"
      :group-type-id="selectedGroup.type_id"
      @close="resetSelectedGroup"
    />

    <generate-badges-modal
      v-if="selectedGroup"
      :group-id="selectedGroup.id"
      @close="resetSelectedGroup"
    />

    <new-task-modal
      :is-assign-task-grom-groups="true"
    />

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BLink, BOverlay,
  BDropdown, BDropdownItem, BAvatar, BBadge, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'

import { downloadExportFile } from '@core/mixins/downloadExportFile'
import moment from 'moment'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/group/groupStoreModule'
import useGroupList from '@/views/admin/group/group-list/useGroupList'
import GroupCreateModal from '@/views/admin/group/group-forms/GroupCreateModal.vue'
import GroupTypeCreateModal from '@/views/admin/group-types/goup-types-add/GroupTypeCreateModal.vue'
import GroupBulkEditModal from '@/views/admin/group/group-forms/GroupBulkEditModal.vue'
import GroupBulkAssignModal from '@/views/admin/group/group-forms/GroupBulkAssignModal.vue'
import GroupBulkCreateModal from '@/views/admin/group/group-forms/GroupBulkCreateModal.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import WarningModal from '@/views/components/warning-modal/WarningModal.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import ApplicationsForGroupAutoAssignModal
  from '@/views/components/applications-for-group-auto-asign-modal/ApplicationsForGroupAutoAssignModal.vue'
import GenerateGroupListTemplateModal from '@/views/components/generate-group-list-template-modal/GenerateGroupListTemplateModal.vue'
import GroupUsers from '@/views/admin/group/group-list/GroupUsers.vue'
import GenerateBadgesModal from '@/views/components/generate-badges-modal/GenerateBadgesModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import NewTaskModal from '@/views/components/new-task-modal/NewTaskModal.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BButton,
    BFormCheckbox,
    BOverlay,

    vSelect,
    GroupCreateModal,
    GroupTypeCreateModal,
    GroupBulkEditModal,
    GroupBulkAssignModal,
    GroupBulkCreateModal,
    TableSpinner,
    WarningModal,
    ConfirmationModal,
    ApplicationsForGroupAutoAssignModal,
    GenerateGroupListTemplateModal,
    GroupUsers,
    GenerateBadgesModal,
    NewTaskModal,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      filterSessionOptions: [],
      groupTypeOptions: [],
      markedGroups: [],
      groupsPerPageCheckbox: false,
      warningModalToggle: false,
      confirmationModalToggle: false,
      deletableGroupId: null,
      createdGroupId: null,
      applicationsForAutoAssign: [],
      selectedGroup: null,
      permissionSubjects,
      isLoading: false,
      currentGroupsUsers: {},
    }
  },
  computed: {
    localStorageItemName() {
      return `groupPpaColumns-${this.programId}`
    },
  },
  watch: {
    dataMeta() {
      this.handleGroupsPerPageCheckbox()
    },
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
    programId() {
      this.setSessionsList()
      this.fetchPpaList()
      this.prepareTagSetsListOptions()
    },
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-group'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchGroupList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      filterStatusOptions,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,
      programId,
      expandAll,

      filterDataType,
      filterDataStatus,
      filterDataSession,
      filterDataTuition,

      statusFilter,
      actionOptions,

      refetchData,
      fetchGroupType,
      groupStatusOptions,
      parentGroupOptions,
      filterTuitionFeeOptions,

      resolveStatusVariant,
      clearFilters,
      fetchGroupsUsers,
    } = useGroupList(root)

    return {
      fetchGroupList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      filterStatusOptions,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,
      programId,
      expandAll,

      filterDataType,
      filterDataStatus,
      filterDataSession,
      filterDataTuition,

      statusFilter,
      actionOptions,
      fetchGroupType,
      groupStatusOptions,
      parentGroupOptions,
      filterTuitionFeeOptions,

      refetchData,

      resolveStatusVariant,
      clearFilters,
      fetchGroupsUsers,
    }
  },
  async created() {
    await this.setSessionsList()

    await this.fetchGroupTypes()
    await this.fetchPpaList()
    await this.prepareTagSetsListOptions()
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    deleteItem(item) {
      this.deletableGroupId = item.id

      if (item.member_count > 0) {
        this.$bvModal.show('warning-delete-group')
      } else {
        this.openDeleteConfirmation(item)
      }
    },
    openDeleteConfirmation(item) {
      this.deletableGroupId = item.id
      this.$bvModal.show('confirmation-delete-group')
    },
    async fetchGroupTypes() {
      await this.$store.dispatch('app-create-group/fetchGroupTypesForSelect', {
        programId: this.programId,
        exceptProgramType: 'Program',
      })
        .then(response => {
          this.groupTypeOptions = response.data.data
          this.groupTypeStatus = 'Select Group Type'
        })
    },
    async deleteGroupIfExist() {
      if (this.deletableGroupId) {
        await axios
          .delete(`auth/groups/${this.deletableGroupId}`)
          .then(() => {
            this.$bvModal.hide('confirmation-delete-group')
            this.$bvModal.hide('warning-delete-group')
            this.refetchData()
          })
          .catch(error => error)
      }
    },
    markGroupsOnPage(val) {
      if (val) {
        this.refInvoiceListTable.localItems.forEach(group => {
          const index = this.markedGroups.indexOf(group.id)
          if (index < 0) {
            this.markedGroups.push(group.id)
          }
        })
      } else {
        this.refInvoiceListTable.localItems.forEach(group => {
          const index = this.markedGroups.indexOf(group.id)
          if (index >= 0) {
            this.markedGroups.splice(index, 1)
          }
        })
      }
    },
    handleGroupsPerPageCheckbox() {
      let counter = 0
      this.refInvoiceListTable.localItems.forEach(group => {
        if (this.markedGroups.indexOf(group.id) !== -1) {
          counter += 1
        }
      })

      this.groupsPerPageCheckbox = !!(this.refInvoiceListTable.localItems && counter === this.refInvoiceListTable.localItems.length)
    },
    finishBulkEdit() {
      this.markedGroups = []
      this.groupsPerPageCheckbox = false
      this.refetchData()
    },
    updateGroupsData() {
      this.fetchGroupTypes()
      this.refetchData()
    },
    updatePerPage(val) {
      localStorage.setItem('groupsPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('groupsPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.sortBy = query.sortBy || 'created_at'
      this.filterDataType = query.filterDataType || ''
      this.filterDataStatus = query.filterDataStatus || ''
      this.filterDataSession = query.filterDataSession || ''
      this.filterDataTuition = query.filterDataTuition || ''
    },
    async exportGroups() {
      const formData = {
        exceptProgramType: 'Program',
      }
      await this.$store.dispatch('app-group/exportGroups', formData)
        .then(response => {
          const filename = `groups-export-${moment().format('YYYY-MM-DD')}`
          this.downloadFile(response.data, filename.replace(/\s/g, '-'), 'csv')
        })
    },
    async setSessionsList() {
      this.filterSessionOptions = await this.$store.dispatch('app-group/fetchSessions', { programId: this.programId })
        .then(response => response.data.data.reduce((arr, item) => {
          arr.push({ id: item.id, label: item.name })
          return arr
        }, []))
    },
    async fetchPpaList() {
      try {
        await this.$store.dispatch('app-group/fetchPpaList', { programId: this.programId })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fatching PPA list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async prepareTagSetsListOptions() {
      await this.$store.dispatch('tags/prepareTagSetsListOptions', { programId: this.programId })
    },
    async findApplicationsForAutoAssign(groupId) {
      if (!groupId) {
        return
      }
      this.createdGroupId = groupId
      this.applicationsForAutoAssign = []
      await this.$store.dispatch('app-group/fetchApplicationsForGroupAutoAssign', {
        program_id: this.programId,
        group_id: groupId,
      })
        .then(response => {
          this.applicationsForAutoAssign = response.data.data
        })

      if (this.applicationsForAutoAssign.length > 0) {
        this.$bvModal.show('applications-for-group-auto-assign')
      }
    },
    openGenerateGroupListTemplateModal(val) {
      this.selectedGroup = val
      this.$nextTick(() => {
        this.$bvModal.show('generate-group-list-template')
      })
    },
    openGenerateBagesModal(val) {
      this.selectedGroup = val
      this.$nextTick(() => {
        this.$bvModal.show('generate-badges')
      })
    },
    resetSelectedGroup() {
      this.selectedGroup = null
    },
    async downloadBages(group) {
      try {
        const queryParams = {
          groups: [{ group_id: group.id }],
        }

        await axios.post('/auth/download-badges', queryParams, { responseType: 'blob' }).then(response => {
          if (response.status === 200) {
            this.downloadFile(response.data, `${group.name}(badges)`, 'zip')
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error downloading badges',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async toggleExpandAll() {
      try {
        this.expandAll = !this.expandAll
        this.isLoading = true

        if (this.expandAll) {
          const queryParams = {
            group_ids: this.refInvoiceListTable.localItems.map(item => item.id),
            ppa: JSON.parse(localStorage.getItem(this.localStorageItemName)),
          }
          this.currentGroupsUsers = await this.fetchGroupsUsers(queryParams)
        }

        this.refInvoiceListTable.localItems.forEach(group => {
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          group._showDetails = this.expandAll
        })
      } finally {
        this.isLoading = false
      }
    },
    getGroupUsersByGroupId(id) {
      return this.currentGroupsUsers[id] || null
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .bulk-checkbox {
    margin-left: 0.5rem;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
